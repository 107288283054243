
import {
  computed,
  defineComponent,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  provide,
  ref,
  watch
} from 'vue'
import UnderLine from '@/components/common/UnderLine.vue'
import Select from '@/components/common/Select.vue'
import Input from '@/components/common/Input.vue'
import InputWithButton from '@/components/common/InputWithButton.vue'
import CheckBox from '@/components/common/CheckBox.vue'
import SquareItemFullImage from '@/components/SquareItemFullImage.vue'
import Icon from '@/components/common/Icon.vue'
import RadioButton from '@/components/common/RadioButton.vue'
import AddressInput from '@/components/common/AddressInput.vue'
import SelectCouponModal from '@/components/modal/SelectCouponModal.vue'
import AddOnModal from '@/components/modal/AddOnModal.vue'
import {
  BaseResponse,
  calcShoppingCart,
  Cmdy,
  CmdyCoupon,
  DeliveryList,
  DeliveryShopList,
  Shop,
  DeliveryShop,
  DeliveryType,
  InvoiceType,
  OrderShoppingCartGiveawayCmdy,
  OrderShoppingCartCmdy,
  AddOnCmdy
} from '@/types/ResponseType'
import find from 'lodash/find'
import filter from 'lodash/filter'
import cartStore from '@/composition/cartStore'
import DeliveryListModal from '@/components/modal/DeliveryListModal.vue'
import store from '@/composition/store'
import productStore from '@/composition/productStore'
import orderStore from '@/composition/orderStore'
import map from 'lodash/map'
import cloneDeep from 'lodash/cloneDeep'
// util
import * as requestUrl from '@/utils/requestUrl'
import request from '@/utils/request'
import FavoriteButton from '@/components/common/FavoriteButton.vue'
import ModalConfirm from '@/components/modal/ConfirmModal.vue'
import { ConfirmModal } from '@/types/ConfirmModal'
import moment from 'moment'
import { useRoute, useRouter } from 'vue-router'
import { isEmail, unescapeHTML } from '@/utils/utils'
import {
  isGuiNumberValid // 統一編號
} from 'taiwan-id-validator'
import { useToast, POSITION } from 'vue-toastification'
import { getToken } from '@/utils/auth'
import { state } from '@/store/mutations'
export default defineComponent({
  data () {
    return {
      detail: true
    }
  },
  props: {},
  components: {
    CheckBox,
    SquareItemFullImage,
    UnderLine,
    Input,
    InputWithButton,
    Icon,
    RadioButton,
    Select,
    AddressInput,
    DeliveryListModal,
    FavoriteButton,
    SelectCouponModal,
    ModalConfirm,
    AddOnModal
  },
  setup () {
    onBeforeMount(() => {
      nextTick(() => {
        document.body.setAttribute('style', 'background:#f5f5f5')
      })
    })
    provide('store', store)
    provide('cartStore', cartStore)
    provide('productStore', productStore)
    provide('orderStore', orderStore)
    const toast = useToast()
    const route = useRoute()
    const router = useRouter()

    const step = ref(1)
    const confirmModal = ref()
    const invoiceEmail = ref('')
    const invoiceBizNo = ref('')
    const invoiceCarrier = ref('')
    const invoiceCarrierType = ref('Member')
    const buyerName = ref(store.state.userInfo.Name)
    const buyerMobile = ref(store.state.userInfo.Mobile)
    const buyerEmail = ref(store.state.userInfo.Email)
    const buyerZip = ref(store.state.userInfo.ContactZip)
    const buyerCity = ref(store.state.userInfo.ContactCity || '')
    const buyerDist = ref(store.state.userInfo.ContactDist || '')
    const buyerAddr = ref(store.state.userInfo.ContactAddr || '')
    const isSameMemberEmail = ref(false)
    const isSameMemberBarcode = ref(false)
    const isAllowReceive = ref(true)
    const isAllowReturn = ref(true)
    const isAllowInfoCorrect = ref(true)
    const isRecordBarcode = ref(false)
    const sendOrder = ref(false)
    const remark = ref('')
    const recommendedList = ref()
    const couponIsUpdate = ref(false)
    const orderShoppingCartCmdyListRef = ref([] as OrderShoppingCartCmdy[])

    // 加購彈跳視窗
    const showAddOnModal = ref(false)
    const selectAddOnCmdy = ref({} as any)
    const selectedType = ref('')
    const setSelectAddOnCmdy = (addOnCmdy: any, type: string) => {
      selectedType.value = type
      selectAddOnCmdy.value = addOnCmdy
      showAddOnModal.value = true
    }

    watch(
      () => couponIsUpdate.value,
      () => {
        store.setShowLoading(true)
        calcShoppingCart()
          .then(() => {
            store.setShowLoading(false)
          })
          .catch(() => {
            store.setShowLoading(false)
          })
      }
    )

    watch(
      () => isSameMemberEmail.value,
      (newVal, oldVal) => {
        if (newVal) {
          invoiceEmail.value = buyerEmail.value
        } else {
          invoiceEmail.value = ''
        }
        console.log(newVal, oldVal)
      }
    )
    const getSelectedItem = computed(() => {
      if (!cartStore.state.shoppingCartList) {
        return []
      }

      if (orderShoppingCartCmdyListRef.value.length === 0) {
        return []
      }

      const _selectedItems = filter(
        orderShoppingCartCmdyListRef.value,
        (item: OrderShoppingCartCmdy) => {
          return item.Checked === true
        }
      )

      return _selectedItems
    })
    watch(
      () => getSelectedItem.value,
      () => {
        store.setShowLoading(true)
        calcShoppingCart()
          .then(() => {
            store.setShowLoading(false)
          })
          .catch(() => {
            store.setShowLoading(false)
          })
      }
    )
    const getAllSelectValue = computed(() => {
      if (!cartStore.state.shoppingCartList) {
        return false
      }

      if (orderShoppingCartCmdyListRef.value.length === 0) {
        return false
      }

      return (
        getSelectedItem.value.length ===
        orderShoppingCartCmdyListRef.value.length
      )
    })

    const freightAmt = ref(0)
    const getFreight = (_selectDeliveryType: any) => {
      freightAmt.value = find(
        cartStore.state.deliveryTypeList,
        (item: DeliveryType) => {
          return item.Code === _selectDeliveryType
        }
      ).Freight
      if (_selectDeliveryType === 'Home') {
        request
          .post<BaseResponse<DeliveryList>>(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.getDeliveryAddrList,
            {
              Data: {}
            }
          )
          .then((req) => {
            const resData = req.data
            const deliveryAddrList = resData.Data.DeliveryAddrList

            let deliverySelect =
              find(deliveryAddrList, (item) => {
                return item.IsDefault === 1
              }) || deliveryAddrList[0]
            if (cartStore.state.shoppingCartDetailInfo.Delivery) {
              deliverySelect =
                find(deliveryAddrList, (item) => {
                  return (
                    item.DeliveryAddrId ===
                    cartStore.state.shoppingCartDetailInfo.Delivery
                      .DeliveryAddrId
                  )
                }) || deliverySelect
            }
            const _shoppingCartDetailInfo = cloneDeep(
              cartStore.state.shoppingCartDetailInfo
            )
            _shoppingCartDetailInfo.Delivery = deliverySelect
            cartStore.setShoppingCartDetailInfo(_shoppingCartDetailInfo)
            store.setShowLoading(true)
            calcShoppingCart()
              .then(() => {
                store.setShowLoading(false)
              })
              .catch(() => {
                store.setShowLoading(false)
              })
          })
          .catch((error) => {
            console.error(error)
            // alert(error)
          })
      } else if (_selectDeliveryType === 'GainHowShopSeller') {
        request
          .post<BaseResponse<DeliveryShopList>>(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.getDeliveryShopList,
            {
              Data: {}
            }
          )
          .then((req) => {
            const resData = req.data
            const DeliveryShopList = resData.Data.DeliveryShopList

            let deliverySelect =
              find(DeliveryShopList, (item) => {
                return item.IsDefault === 1
              }) || DeliveryShopList[0]
            if (cartStore.state.shoppingCartDetailInfo.Delivery) {
              deliverySelect =
                find(DeliveryShopList, (item) => {
                  return (
                    item.DeliveryShopId ===
                    cartStore.state.shoppingCartDetailInfo.Delivery
                      .DeliveryShopId
                  )
                }) || deliverySelect
            }
            const _shoppingCartDetailInfo = cloneDeep(
              cartStore.state.shoppingCartDetailInfo
            )
            _shoppingCartDetailInfo.Delivery = deliverySelect
            cartStore.setShoppingCartDetailInfo(_shoppingCartDetailInfo)
            store.setShowLoading(true)
            calcShoppingCart()
              .then(() => {
                store.setShowLoading(false)
              })
              .catch(() => {
                store.setShowLoading(false)
              })
          })
          .catch((error) => {
            console.error(error)
            // alert(error)
          })
        // const _shoppingCartDetailInfo = cloneDeep(
        //   cartStore.state.shoppingCartDetailInfo
        // )
        // cartStore.setShoppingCartDetailInfo(_shoppingCartDetailInfo)
        // store.setShowLoading(true)
        // calcShoppingCart()
        //   .then(() => {
        //     store.setShowLoading(false)
        //   })
        //   .catch(() => {
        //     store.setShowLoading(false)
        //   })
      }
    }
    const getSelectPrice = computed(() => {
      let _cmdyAmt = 0
      let _discount = 0
      let _freightDiscount = 0
      let _totalAmount = 0
      let _forFree = 0
      let _forFreeBalanceEcPayCVS = 599
      const startTime = new Date('2024-07-17 00:00:00')
      const finishTime = new Date('2024-08-19 00:00:00')
      let _forFreeBalance = 0

      if (finishTime.getTime() > Date.now() && startTime.getTime() < Date.now()) {
        _forFreeBalance = 599
      } else {
        _forFreeBalance = 1000
      }
      let _forFreeDesc = ''
      getSelectedItem.value.forEach((item: any) => {
        let _addOnAmt = 0
        const _packageAmt = item.PackageCmdyList[0]?.UnitFeeReal || 0
        item.AddOnCmdyList.forEach((addOnItem: any) => {
          _addOnAmt += addOnItem.UnitFeeReal * addOnItem.CmdyQty
        })
        _cmdyAmt += item.CmdyAmt + _addOnAmt + _packageAmt
        _discount += item.Discount
        _totalAmount += item.TotalAmount + _addOnAmt + _packageAmt
      })
      // 滿額加購
      getCheckedAvailableAddOnCmdyList.value.forEach((item: any) => {
        _cmdyAmt += item.UnitFeeReal * item.CmdyQty
        _totalAmount += item.UnitFeeReal * item.CmdyQty
      })
      _forFreeBalanceEcPayCVS = _forFreeBalanceEcPayCVS - _totalAmount
      _forFreeBalance = _forFreeBalance - _totalAmount
      if (finishTime.getTime() > Date.now() && startTime.getTime() < Date.now()) {
        _forFreeDesc = `<div>滿 NT.599 可享 門店、超商免運、宅配免運 ，<span class="text-primary-color">還差 NT. ${_forFreeBalanceEcPayCVS}</span></div>`
      } else {
        _forFreeDesc = `<div>滿 NT.599 可享 門店、超商免運 ，<span class="text-primary-color">還差 NT. ${_forFreeBalanceEcPayCVS}</span></div><div>滿 NT.1000 可享 宅配免運，<span class="text-primary-color">還差 NT. ${_forFreeBalance}</span></div>`
      }
      // _forFreeDesc = `<div>滿 NT.599 可享 超商免運 ，<span class="text-primary-color">還差 NT. ${_forFreeBalanceEcPayCVS}</span></div><div>滿 NT.1000 可享 宅配免運，<span class="text-primary-color">還差 NT. ${_forFreeBalance}</span></div>`
      // _forFreeDesc = `<div>滿 NT.599 可享 門店、超商免運、宅配免運 ，<span class="text-primary-color">還差 NT. ${_forFreeBalanceEcPayCVS}</span></div>`
      if (_totalAmount / 10 >= 59.9) {
        _forFreeDesc = `<div>恭喜！已達 超商取貨、門店取貨 免運門檻！\n<span class="text-primary-color">還差 NT.${_forFreeBalance}</span>，可享 宅配免運！</div>`
        _forFree = 60
        _freightDiscount = freightAmt.value
        if (finishTime.getTime() > Date.now() && startTime.getTime() < Date.now()) {
          if (_totalAmount / 10 >= 59.9) {
            _forFreeDesc = '<div>恭喜！已達 宅配免運、超商取貨、門店取貨 免運門檻！</div>'
            _forFree = 100
            _freightDiscount = freightAmt.value
          }
        } else {
          if (_totalAmount / 10 >= 100) {
            _forFreeDesc = '<div>恭喜！已達 宅配免運、超商取貨、門店取貨 免運門檻！</div>'
            _forFree = 100
            _freightDiscount = freightAmt.value
          }
        }
      } else {
        _forFree = _totalAmount / 10
        _freightDiscount = 0
      }

      return {
        CmdyAmt: _cmdyAmt,
        Discount: _discount,
        FreightDiscount: _freightDiscount,
        TotalAmount: _totalAmount + freightAmt.value - _freightDiscount,
        ForFree: _forFree,
        ForFreeDesc: _forFreeDesc
      }
    })

    const showDeliveryListModal = ref(false)
    const showSelectCouponModal = ref(false)
    const selectDeliveryType = ref('')
    const selectDeliveryShop = ref('')
    const selectDeliverySubType = ref('')
    const selectDeliveryCity = ref('')

    const selectDeliverySubTypeDesc = ref('')
    const selectPaymentType = ref({} as any)
    const selectInvoiceType = ref('')
    const SuccessOrderItem = ref(null)
    const parseDeliveryTypeCode = (code: string) => {
      const deliveryTypeObj =
        (cartStore.state.deliveryTypeList,
        (item: DeliveryType) => {
          return item.Code === code
        })

      return deliveryTypeObj
    }
    const parseInvoiceTypeCode = (code: string) => {
      const invoiceTypeObj = find(
        cartStore.state.invoiceTypeList,
        (item: InvoiceType) => {
          return item.Code === code
        }
      )

      return invoiceTypeObj
    }

    // watch(
    //   () => cartStore.state.orderShoppingCartCmdyList,
    //   (newVal: any) => {
    //     console.log(newVal)
    //     orderShoppingCartCmdyListRef.value = cloneDeep(newVal)
    //   }
    // )

    const allItemSelect = (checked: boolean) => {
      map(orderShoppingCartCmdyListRef.value, (item: any) => {
        item.Checked = !checked
      })
    }
    const subtractClick = (shoppingCartItem: OrderShoppingCartCmdy) => {
      if (shoppingCartItem.CmdyQty === 1) {
        return
      }

      shoppingCartItem.CmdyQty = shoppingCartItem.CmdyQty - 1

      itemUpdate(shoppingCartItem)
    }
    const addClick = (shoppingCartItem: OrderShoppingCartCmdy) => {
      if (shoppingCartItem.CmdyQty >= 100) {
        shoppingCartItem.CmdyQty = 100
        itemUpdate(shoppingCartItem)
        return toast.warning('已達訂購上限', {
          position: POSITION.TOP_CENTER
        })
      }
      shoppingCartItem.CmdyQty = shoppingCartItem.CmdyQty + 1

      itemUpdate(shoppingCartItem)
    }
    const itemDelete = async (orderShoppingCartId?: string, type = 'cmdy') => {
      try {
        const _confirmModal = confirmModal.value as ConfirmModal
        _confirmModal.createConfirm('確定刪除此商品？', '', false).then(() => {
          cartStore.delShoppingCart(orderShoppingCartId, 0, type, 0)
          orderShoppingCartCmdyListRef.value.forEach((item, index) => {
            if (type === 'addOn') {
              item.AddOnCmdyList.forEach((addOnItem, addOnIndex) => {
                if (addOnItem.OrderShoppingCartId === orderShoppingCartId) {
                  orderShoppingCartCmdyListRef.value[
                    index
                  ].AddOnCmdyList.splice(addOnIndex, 1)
                }
              })
            }
            if (item.OrderShoppingCartId === orderShoppingCartId) {
              orderShoppingCartCmdyListRef.value.splice(index, 1)
            }
          })
          // remove(orderShoppingCartCmdyListRef.value, (item) => {
          //   return item.OrderShoppingCartId === OrderShoppingCartId
          // })
          orderShoppingCartCmdyListRef.value = cloneDeep(
            orderShoppingCartCmdyListRef.value
          )
          cartStore.fetchShoppingCartList()
          store.setShowLoading(true)
          calcShoppingCart()
            .then(() => {
              store.setShowLoading(false)
            })
            .catch(() => {
              store.setShowLoading(false)
            })
        })
      } catch (err) {
        console.log(err)
      }
    }
    const itemUpdate = (itemInfo: any, itemType: any = 'qty') => {
      if (itemInfo.CmdyQty === '' || parseInt(itemInfo.CmdyQty) === 0) {
        itemInfo.CmdyQty = 1
        itemUpdate(itemInfo)
        // itemDelete(itemInfo.OrderShoppingCartId)
      } else {
        if (itemType === 'collect') {
          map(orderShoppingCartCmdyListRef.value, (item: any) => {
            if (item.CmdyId === itemInfo.CmdyId) {
              item.IsInCmdyCollect = itemInfo.IsInCmdyCollect
              item.CmdyCollectId = itemInfo.CmdyCollectId
            }
          })
        }
        if (itemType === 'qty') {
          itemInfo.CmdyQty = parseInt(itemInfo.CmdyQty)
          if (itemInfo.PackageCmdyList?.length > 0) {
            itemInfo.PackageCmdyList[0].CmdyQty = parseInt(itemInfo.CmdyQty)
          }
          cartStore.updateShoppingCart(itemInfo).then((res) => {
            map(orderShoppingCartCmdyListRef.value, (item: any) => {
              if (
                item.OrderShoppingCartId ===
                res.orderShoppingCartCmdy?.OrderShoppingCartId
              ) {
                item.CmdyAmt = res.orderShoppingCartCmdy.CmdyAmt
                item.TotalAmount = res.orderShoppingCartCmdy.TotalAmount
                item.UnitFeeOri = res.orderShoppingCartCmdy.UnitFeeOri
                item.UnitFeeReal = res.orderShoppingCartCmdy.UnitFeeReal
                item.Discount = res.orderShoppingCartCmdy.Discount
              }
            })
          })
        }
        store.setShowLoading(true)
        calcShoppingCart()
          .then(() => {
            store.setShowLoading(false)
          })
          .catch(() => {
            store.setShowLoading(false)
          })
      }
    }
    const addOnItemUpdate = (addOnItem: any, action: any) => {
      switch (action) {
        case 'add':
          if (addOnItem.CmdyQty >= 100) {
            addOnItem.CmdyQty = 100
            addOnItemUpdate(addOnItem, 'update')
            return toast.warning('已達訂購上限', {
              position: POSITION.TOP_CENTER
            })
          }
          if (addOnItem.CmdyQty < addOnItem.MaxAddOnQtyPerOrder) {
            addOnItem.CmdyQty = addOnItem.CmdyQty + 1
          } else {
            addOnItem.CmdyQty = addOnItem.MaxAddOnQtyPerOrder
            return toast.warning('已達訂購上限', {
              position: POSITION.TOP_CENTER
            })
          }

          addOnItemUpdate(addOnItem, 'update')
          break
        case 'subtract':
          if (addOnItem.CmdyQty === 1) {
            return
          }

          addOnItem.CmdyQty = addOnItem.CmdyQty - 1

          addOnItemUpdate(addOnItem, 'update')
          break
        case 'delete':
          try {
            const _confirmModal = confirmModal.value as ConfirmModal
            _confirmModal
              .createConfirm('確定刪除此加購商品？', '', false)
              .then(() => {
                selectedAvailableAddOnCmdyList.value.forEach((item, index) => {
                  if (
                    item.CmdyId === addOnItem.CmdyId &&
                    item.PromotionActivityId === addOnItem.PromotionActivityId
                  ) {
                    selectedAvailableAddOnCmdyList.value.splice(index, 1)
                  }
                })
                // orderShoppingCartCmdyListRef.value = cloneDeep(
                //   orderShoppingCartCmdyListRef.value
                // )
                store.setShowLoading(true)
                calcShoppingCart()
                  .then(() => {
                    store.setShowLoading(false)
                  })
                  .catch(() => {
                    store.setShowLoading(false)
                  })
              })
          } catch (err) {
            console.log(err)
          }
          break
        case 'update':
          map(selectedAvailableAddOnCmdyList.value, (item: any) => {
            if (
              item.CmdyId === addOnItem.CmdyId &&
              item.PromotionActivityId === addOnItem.PromotionActivityId
            ) {
              if (addOnItem.CmdyQty < addOnItem.MaxAddOnQtyPerOrder) {
                item = addOnItem
              } else {
                addOnItem.CmdyQty = addOnItem.MaxAddOnQtyPerOrder
                item = addOnItem
                return toast.warning('已達訂購上限', {
                  position: POSITION.TOP_CENTER
                })
              }
            }
          })
          store.setShowLoading(true)
          calcShoppingCart()
            .then(() => {
              store.setShowLoading(false)
            })
            .catch(() => {
              store.setShowLoading(false)
            })

          break
        default:
      }
    }

    const continueEdit = (shoppingCartItem: any) => {
      const workSetID = shoppingCartItem.EditorPartnerBookId
      const PartnerBookId = shoppingCartItem.EditorPartnerBookId
      const orderurl = encodeURIComponent(
        window._env_.VUE_APP_STOREBOOK_SERVICE_API +
          '/orders/add-shopping-cart-from-editor?Token=' +
          getToken() +
          '&PartnerBookId=' +
          PartnerBookId +
          '&CmdyQty=' +
          shoppingCartItem.CmdyQty
      )
      let exiturl = ''
      let returnurl = ''
      if (window._env_.ENV === 'develop') {
        exiturl = encodeURIComponent(
          'http://localhost:9123' + router.currentRoute.value.fullPath
        )
        returnurl = encodeURIComponent('http://localhost:9123/shoppingCart')
      } else {
        exiturl = encodeURIComponent(
          window._env_.VUE_APP_GIFT_URL + router.currentRoute.value.fullPath
        )
        returnurl = encodeURIComponent(
          window._env_.VUE_APP_GIFT_URL + '/shoppingCart'
        )
      }

      window.location.href =
        window._env_.VUE_APP_GIFT_EDITOR_URL +
        `/auth/${workSetID}/ip?orderurl=${orderurl}&exiturl=${exiturl}&returnurl=${returnurl}&edittype=1&distributorid=${store.state.userInfo.Account}`
    }
    const addToCollect = async (item: any) => {
      console.log(item)
      if (!item.IsInCmdyCollect) {
        productStore.addCmdyCollect(item.CmdyId).then((res) => {
          item.IsInCmdyCollect = true
          item.CmdyCollectId = res.collectId
          itemUpdate(item, 'collect')
        })
        // try {
        //   const _confirmModal = confirmModal.value as ConfirmModal
        //   _confirmModal
        //     .createConfirm('確定移至我的最愛，下次再訂購？', '', false)
        //     .then(() => {

        //     })
        // } catch (err) {
        //   console.log(err)
        // }
      } else {
        const collectIdArray: string[] = []
        collectIdArray.push(item.CmdyCollectId)
        productStore.delCmdyCollect(collectIdArray).then(() => {
          item.IsInCmdyCollect = false
          itemUpdate(item, 'collect')
        })
        // try {
        //   const _confirmModal = confirmModal.value as ConfirmModal
        //   _confirmModal
        //     .createConfirm('確定要從我的最愛移除？', '', false)
        //     .then(() => {
        //     })
        // } catch (err) {
        //   console.log(err)
        // }
      }
    }
    const cmdyListFromCalc = ref([] as Cmdy[])
    const availableAddOnCmdyListFromCalc = ref([] as any[])
    const selectedAvailableAddOnCmdyList = ref([] as AddOnCmdy[])
    // const getSelectedAvailableAddOnCmdyList = computed(() => {
    //   const _selectedItems = filter(
    //     availableAddOnCmdyListFromCalc.value,
    //     (item: any) => {
    //       return item.Selected === true
    //     }
    //   )

    //   return _selectedItems
    // })
    const getCheckedAvailableAddOnCmdyList = computed(() => {
      const _selectedItems = filter(
        selectedAvailableAddOnCmdyList.value,
        (item: AddOnCmdy) => {
          return item.Checked === true
        }
      )

      return _selectedItems
    })
    const addOnCmdyListFromCalc = ref([] as AddOnCmdy[])
    const giveawayCmdyListFromCalc = ref([] as OrderShoppingCartGiveawayCmdy[])
    const calcShoppingCartObject = ref({} as calcShoppingCart)
    const selectCouponList = ref([] as CmdyCoupon[])
    const cancelCoupon = (cmdyCoupon: CmdyCoupon) => {
      selectCouponList.value.forEach((item, index) => {
        if (item.PromotionCouponId === cmdyCoupon.PromotionCouponId) {
          selectCouponList.value.splice(index, 1)
        }
      })

      couponIsUpdate.value = !couponIsUpdate.value
    }
    const calcShoppingCart = (): Promise<{
      cmdyList: Cmdy[]
    }> => {
      return new Promise((resolve, reject) => {
        const _cmdyList = map(getSelectedItem.value, (item: any) => {
          if (item.PortfolioId === undefined) {
            item.PortfolioId = item.EditorPortfolioId
          }
          return item
        })
        const data = {
          Data: {
            DeliveryType: selectDeliveryType.value,
            DeliverySubType: selectDeliverySubType.value,
            CmdyList: _cmdyList,
            PromotionList: selectCouponList.value,
            AddOnCmdyList: getCheckedAvailableAddOnCmdyList.value
          }
        }
        request
          .post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.calcShoppingCart,
            data
          )
          .then((res) => {
            const resData: calcShoppingCart = res.data.Data
            calcShoppingCartObject.value = resData
            cmdyListFromCalc.value = resData.CmdyList
            orderShoppingCartCmdyListRef.value.forEach(
              (orderShoppingCartCmdyListRefItem: any) => {
                cmdyListFromCalc.value.forEach((cmdyListFromCalcItem: any) => {
                  if (
                    orderShoppingCartCmdyListRefItem.CmdyId ===
                    cmdyListFromCalcItem.CmdyId
                  ) {
                    orderShoppingCartCmdyListRefItem.GiveawayCmdyList =
                      cmdyListFromCalcItem.GiveawayCmdyList
                  }
                })
              }
            )
            giveawayCmdyListFromCalc.value = resData.GiveawayCmdyList
            availableAddOnCmdyListFromCalc.value =
              resData.AvailableAddOnCmdyList

            selectedAvailableAddOnCmdyList.value =
              selectedAvailableAddOnCmdyList.value.filter(
                ({ CmdyId, PromotionActivityId }) =>
                  availableAddOnCmdyListFromCalc.value.some(
                    (include) =>
                      include.CmdyId === CmdyId &&
                      include.PromotionActivityId === PromotionActivityId
                  )
              )
            // selectedAvailableAddOnCmdyList.value =
            //   selectedAvailableAddOnCmdyList.value.filter((item) => {
            //     return availableAddOnCmdyListFromCalc.value.indexOf(item) > 1
            //   })
            // selectedAvailableAddOnCmdyList.value.forEach((item) => {
            //   availableAddOnCmdyListFromCalc.value.forEach((availableItem) => {
            //     if (
            //       item.CmdyId === availableItem.CmdyId &&
            //       item.PromotionActivityId === availableItem.PromotionActivityId
            //     ) {
            //     }
            //   })
            // })
            addOnCmdyListFromCalc.value = resData.AddOnCmdyList
            calcShoppingCartObject.value.CmdyCouponList = resData.CmdyCouponList

            selectCouponList.value = filter(
              resData.CmdyCouponList,
              (item: CmdyCoupon) => {
                return item.IsUsing === true
              }
            )
            resolve({
              cmdyList: cmdyListFromCalc.value
            })
          })
          .catch((error) => {
            giveawayCmdyListFromCalc.value = []
            availableAddOnCmdyListFromCalc.value = []
            selectedAvailableAddOnCmdyList.value = []
            reject(error)
            console.error(error)
          })
      })
    }
    const order = async () => {
      store.setShowLoading(true)
      if (!isAllowReturn.value || !isAllowInfoCorrect.value) {
        store.setShowLoading(false)
        return toast.warning('請勾選以上說明', {
          position: POSITION.TOP_CENTER
        })
      }

      if (
        buyerName.value === '' ||
        buyerMobile.value === '' ||
        buyerEmail.value === '' ||
        buyerCity.value === '' ||
        buyerDist.value === '' ||
        buyerAddr.value === ''
      ) {
        store.setShowLoading(false)
        return toast.warning('訂購人資料為空', {
          position: POSITION.TOP_CENTER
        })
      }
      if (!isEmail(buyerEmail.value)) {
        store.setShowLoading(false)
        return toast.warning('信箱格式錯誤', {
          position: POSITION.TOP_CENTER
        })
      }
      if (selectDeliveryType.value === '') {
        store.setShowLoading(false)
        return toast.warning('請選擇運送方式', {
          position: POSITION.TOP_CENTER
        })
      }
      if (!cartStore.state.shoppingCartDetailInfo.Delivery) {
        store.setShowLoading(false)
        return toast.warning('請填寫收件資料', {
          position: POSITION.TOP_CENTER
        })
      }
      if (selectPaymentType.value.Code === undefined) {
        store.setShowLoading(false)
        return toast.warning('請勾選付款方式', {
          position: POSITION.TOP_CENTER
        })
      }
      if (selectInvoiceType.value === '') {
        store.setShowLoading(false)
        return toast.warning('請選擇發票類型', {
          position: POSITION.TOP_CENTER
        })
      }
      if (selectInvoiceType.value === 'Elec2') {
        invoiceEmail.value = buyerEmail.value
        invoiceCarrierType.value = 'Member'
        if (!isEmail(invoiceEmail.value)) {
          store.setShowLoading(false)
          return toast.warning('信箱格式錯誤', {
            position: POSITION.TOP_CENTER
          })
        }
      }
      if (selectInvoiceType.value === 'Elec2WithBarcode') {
        if (invoiceCarrier.value === '') {
          store.setShowLoading(false)
          return toast.warning('請輸入手機條碼載具', {
            position: POSITION.TOP_CENTER
          })
        }
        invoiceCarrierType.value = 'Barcode'

        try {
          store.setShowLoading(true)
          const checkRes = await request.post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API +
              requestUrl.verifyBarcode,
            { Data: { Barcode: invoiceCarrier.value } }
          )
          // toast.warning(checkRes)
          console.log(checkRes)

          store.setShowLoading(false)
        } catch (e: any) {
          console.log(e)
          store.setShowLoading(false)
          if (e.data.Message) {
            toast.warning(e.data.Message)
          }
          return
        }
      }

      if (selectInvoiceType.value === 'Elec3') {
        invoiceCarrierType.value = 'Member'
        if (!isGuiNumberValid(invoiceBizNo.value, true)) {
          store.setShowLoading(false)
          return toast.warning('統一編號格式錯誤', {
            position: POSITION.TOP_CENTER
          })
        }
        if (invoiceEmail.value === '') {
          store.setShowLoading(false)
          return toast.warning('請輸入發票資訊欄的信箱', {
            position: POSITION.TOP_CENTER
          })
        }
        if (!isEmail(invoiceEmail.value)) {
          store.setShowLoading(false)
          return toast.warning('信箱格式錯誤', {
            position: POSITION.TOP_CENTER
          })
        }

        try {
          store.setShowLoading(true)
          const checkRes = await request.post(
            window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.checkBIZNo,
            { Data: { BizNo: invoiceBizNo.value } }
          )
          // toast.warning(checkRes)
          console.log(checkRes)

          store.setShowLoading(false)
        } catch (e: any) {
          console.log(e)
          store.setShowLoading(false)
          if (e.data.Message) {
            toast.warning(e.data.Message, {
              position: POSITION.TOP_CENTER
            })
          }
          return
        }
      }

      const _shoppingCartDetailInfo = cloneDeep(
        cartStore.state.shoppingCartDetailInfo
      )

      _shoppingCartDetailInfo.Payment = {
        PaymentType: selectPaymentType.value.Code,
        CmdyAmt: calcShoppingCartObject.value.TotalCmdyAmount,
        Discount:
          calcShoppingCartObject.value.TotalCmdyDiscount +
          calcShoppingCartObject.value.FreightDiscount,
        TotalAmount: calcShoppingCartObject.value.TotalShoppingCartAmount,
        FreightAmt: calcShoppingCartObject.value.FreightAmount
      }
      _shoppingCartDetailInfo.Buyer = {
        BuyerName: buyerName.value,
        BuyerMobile: buyerMobile.value,
        BuyerEmail: buyerEmail.value,
        BuyerCity: buyerCity.value,
        BuyerDist: buyerDist.value,
        BuyerAddr: buyerAddr.value,
        IsUpdateToMember: true
      }
      if (selectDeliveryType.value === 'Home') {
        _shoppingCartDetailInfo.Receiver = {
          ReceiverName: _shoppingCartDetailInfo.Delivery.ReceiverName,
          ReceiverTel: '',
          ReceiverMobile: _shoppingCartDetailInfo.Delivery.ReceiverMobile,
          ReceiverEmail: ''
        }
      }

      if (selectDeliveryType.value === 'GainHowShopSeller') {
        _shoppingCartDetailInfo.Receiver = {
          ReceiverName: _shoppingCartDetailInfo.Delivery.ReceiverName,
          ReceiverTel: '',
          ReceiverMobile: _shoppingCartDetailInfo.Delivery.ReceiverMobile,
          ReceiverEmail: ''
        }

        _shoppingCartDetailInfo.Delivery = {
          DeliveryDestCode:
            cartStore.state.shoppingCartDetailInfo.Delivery.ShopInfo.ShopCode,
          DeliveryDestName:
            cartStore.state.shoppingCartDetailInfo.Delivery.ShopInfo.ShopName
        }
      }
      // else if (selectDeliveryType.value === 'EcPayCVS') {
      //   _shoppingCartDetailInfo.Receiver
      // }
      _shoppingCartDetailInfo.Invoice = {
        InvoiceBizNo: invoiceBizNo.value,
        InvoiceType: selectInvoiceType.value,
        InvoiceEmail: invoiceEmail.value,
        InvoiceCarrierType: invoiceCarrierType.value,
        InvoiceCarrier: invoiceCarrier.value
      }
      _shoppingCartDetailInfo.Delivery.DeliveryType = selectDeliveryType.value
      _shoppingCartDetailInfo.Delivery.DeliverySubType =
        selectDeliverySubType.value

      // const _cmdyList = map(getSelectedItem.value, (item: any) => {
      //   if (item.PortfolioId === undefined) {
      //     item.PortfolioId = item.EditorPortfolioId
      //   }
      //   return item
      // })
      const _cmdyList = calcShoppingCartObject.value.CmdyList
      const _addOnCmdyList = addOnCmdyListFromCalc.value

      _shoppingCartDetailInfo.CmdyList = _cmdyList
      _shoppingCartDetailInfo.AddOnCmdyList = _addOnCmdyList

      _shoppingCartDetailInfo.Info = {
        ShopCode: 'G-Gift',
        OrderCode: '',
        Remark: remark.value
      }

      _shoppingCartDetailInfo.GiveawayCmdyList = giveawayCmdyListFromCalc.value
      // if (getSelectPrice.value.FreightDiscount > 0) {
      //   _shoppingCartDetailInfo.PromotionList = [
      //     {
      //       PromotionActivityId: '4466bc5f-8b2b-11ed-a1ff-0242ac1b0002',
      //       PromotionCouponId: '',
      //       Discount: getSelectPrice.value.FreightDiscount
      //     }
      //   ]
      // } else {
      //   _shoppingCartDetailInfo.PromotionList = null
      // }
      _shoppingCartDetailInfo.PromotionList =
        calcShoppingCartObject.value.AppliedPromotionList

      cartStore.setShoppingCartDetailInfo(_shoppingCartDetailInfo)
      const data = {
        Data: cartStore.state.shoppingCartDetailInfo
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API + requestUrl.addOrder,
          data,
          {
            headers: {
              'api-version': '2.0'
            }
          }
        )
        .then((res) => {
          const resData = res.data.Data
          cartStore.fetchShoppingCartList()
          if (
            data.Data.Payment.PaymentType === 'CreditCard' ||
            data.Data.Payment.PaymentType === 'LinePay'
          ) {
            // alert(`${selectPaymentType.value.GoToUrl}?${window._env_.VUE_APP_PAYMENT_PARAMS}${window.location.protocol}//${window.location.host}/orderSuccess&OrderId=${resData.OrderId}&RedirectFailUrl=${window.location.protocol}/${window.location.host}/orderDetail/${resData.OrderId}`)
            window.location.href = `${selectPaymentType.value.GoToUrl}?${window._env_.VUE_APP_PAYMENT_PARAMS}${window.location.protocol}//${window.location.host}/orderSuccess&OrderId=${resData.OrderId}&RedirectFailUrl=${window.location.protocol}//${window.location.host}/orderDetail/${resData.OrderId}`
          } else if (data.Data.Payment.PaymentType === 'ATM') {
            sendOrder.value = false
            store.setShowLoading(false)
            if (resData.AtmPayment) {
              if (resData.AtmPayment.PaymentDeadline) {
                resData.AtmPayment.PaymentDeadline = moment
                  .utc(resData.AtmPayment.PaymentDeadline, 'YYYYMMDDHHmmss')
                  .local()
                  .format('YYYY-MM-DD HH:mm:ss')
              }
              (document.getElementById('app') as HTMLBodyElement).scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
              })
              router.push({
                name: 'orderSuccess',
                params: {
                  bankAccount: resData.AtmPayment.BankAccount,
                  bankCode: resData.AtmPayment.BankCode,
                  bankName: resData.AtmPayment.BankName,
                  orderId: resData.OrderId,
                  paymentDateLine: resData.AtmPayment.PaymentDeadline
                }
              })
            } else {
              sendOrder.value = false
              // this.$store.dispatch('actionChangeShowLoadingViewStatus', false)
              toast.warning('取得銀行帳號失敗正在轉導至訂單清單', {
                position: POSITION.TOP_CENTER
              })
              // this.$toasted.show('取得銀行帳號失敗正在轉導至訂單清單')
              window.setTimeout(() => {
                router.push({ name: 'orderList' })
              }, 3000)
            }
          }
        })
        .catch((error) => {
          sendOrder.value = false
          toast.warning(error.data.Message, {
            position: POSITION.TOP_CENTER
          })
          store.setShowLoading(false)
          console.error(error)
        })
    }
    const tempShoppingCartId = ref(route.query.TempShoppingCartId)
    const goDeliveryListModal = () => {
      if (selectDeliveryType.value === '') {
        toast.warning('請選擇運送方式', {
          position: POSITION.TOP_CENTER
        })
      } else {
        if (
          selectDeliveryType.value === 'Home' ||
          selectDeliveryType.value === 'GainHowShopSeller'
        ) {
          showDeliveryListModal.value = true
        } else {
          store.setShowLoading(true)
          const _shoppingCartDetailInfo = cloneDeep(
            cartStore.state.shoppingCartDetailInfo
          )

          _shoppingCartDetailInfo.Payment = {
            PaymentType: selectPaymentType.value.Code,
            CmdyAmt: calcShoppingCartObject.value.TotalCmdyAmount,
            Discount:
              calcShoppingCartObject.value.TotalCmdyDiscount +
              calcShoppingCartObject.value.FreightDiscount,
            TotalAmount: calcShoppingCartObject.value.TotalShoppingCartAmount,
            FreightAmt: calcShoppingCartObject.value.FreightAmount
          }
          _shoppingCartDetailInfo.Buyer = {
            BuyerName: buyerName.value,
            BuyerMobile: buyerMobile.value,
            BuyerEmail: buyerEmail.value,
            BuyerCity: buyerCity.value,
            BuyerDist: buyerDist.value,
            BuyerAddr: buyerAddr.value,
            IsUpdateToMember: true
          }
          _shoppingCartDetailInfo.Receiver = {
            ReceiverName: _shoppingCartDetailInfo.Delivery?.ReceiverName || '',
            ReceiverTel: '',
            ReceiverMobile:
              _shoppingCartDetailInfo.Delivery?.ReceiverMobile || '',
            ReceiverEmail: ''
          }
          _shoppingCartDetailInfo.Invoice = {
            InvoiceBizNo: invoiceBizNo.value,
            InvoiceType: selectInvoiceType.value,
            InvoiceEmail: invoiceEmail.value,
            InvoiceCarrierType: invoiceCarrierType.value,
            InvoiceCarrier: invoiceCarrier.value
          }
          _shoppingCartDetailInfo.Delivery = {
            DeliveryType: selectDeliveryType.value
          }
          const _cmdyList = calcShoppingCartObject.value.CmdyList
          const _addOnCmdyList = addOnCmdyListFromCalc.value

          _shoppingCartDetailInfo.CmdyList = _cmdyList
          _shoppingCartDetailInfo.AddOnCmdyList = _addOnCmdyList

          _shoppingCartDetailInfo.Info = {
            ShopCode: 'G-Gift',
            OrderCode: '',
            Remark: remark.value
          }
          _shoppingCartDetailInfo.PromotionList =
            calcShoppingCartObject.value.AppliedPromotionList

          _shoppingCartDetailInfo.TempShoppingCartId = tempShoppingCartId.value

          const _cannotUseLogisticsList = [
            '109763b2-3894-470c-ace9-18cd9f361bce',
            '19db47ce-b3ee-4164-8135-8ef14c4aeb80',
            '1cef9ce7-f311-4b1a-a556-c78360be15c8',
            '2bce7e55-976f-4e47-b436-3c76e8962651',
            '109763b2-3894-470c-ace9-18cd9f361bce',
            '18ce2c91-5935-11ef-b302-0242ac1b0002',
            '18e95f44-5935-11ef-b302-0242ac1b0002',
            '19db47ce-b3ee-4164-8135-8ef14c4aeb80',
            '1cef9ce7-f311-4b1a-a556-c78360be15c8',
            '1e69ba74-72d5-11ee-b302-0242ac1b0002',
            '1e8f7c01-72d5-11ee-b302-0242ac1b0002',
            '1ea2f556-72d5-11ee-b302-0242ac1b0002',
            '25a51179-9570-45e7-a2af-5cc4ecebcf42',
            '2bce7e55-976f-4e47-b436-3c76e8962651',
            '428a620b-5417-4248-8633-390a3a093770',
            '42b46c1e-1a0a-486b-aed2-cac9b9e145ac',
            '7306ae79-092e-11ef-b302-0242ac1b0002',
            '734ac6d9-092e-11ef-b302-0242ac1b0002',
            '7c76f589-8250-49b1-81aa-793540d3197e',
            '9169c7db-1539-4451-bf2e-0652ee05b692',
            '9541ecf9-6374-4164-a94c-c0b990cef8be',
            '9889fa2b-4924-4f25-a7d5-b0e273a9c8da',
            'b2e80602-b4d4-460f-b985-72aac20f0887',
            'b62c1d75-2f75-11ef-b302-0242ac1b0002',
            'b63c49a6-2f75-11ef-b302-0242ac1b0002',
            'b64d3281-2f75-11ef-b302-0242ac1b0002',
            'b66edcc5-2f75-11ef-b302-0242ac1b0002',
            'b67a96cd-2f75-11ef-b302-0242ac1b0002',
            'b971e5ae-62b3-4cd6-ba5d-2a828a549bae',
            'c030a9dd-510a-4178-8b47-85c424618571',
            'c32bdeb9-e6c2-4f1a-bcaa-66b0db0b5e7b',
            'ca1ae2af-fc79-11ee-b302-0242ac1b0002',
            'ce2e28bd-7149-4655-aa99-311f40d3bb13',
            'e04f8a2c-61bc-4be5-a43c-c03cf740ca9c',
            'e8f4bbbc-c04d-4fd8-a7bd-9bb4891bc201',
            'e9b9d71c-75b7-11ee-b302-0242ac1b0002',
            'f2f191da-fdec-11ee-b302-0242ac1b0002',
            'f392871c-fdec-11ee-b302-0242ac1b0002',
            'f7477d8c-501b-4bec-b6d7-d5a6e226754d',
            'f7d90b2b-ac85-4bb2-b6c5-66c2719013b9',
            '8641cb48-9cb0-11ef-b302-0242ac1b0002',
            '864fc48a-9cb0-11ef-b302-0242ac1b0002',
            '865fa7e5-9cb0-11ef-b302-0242ac1b0002',
            '8681c5da-9cb0-11ef-b302-0242ac1b0002',
            '8690ef19-9cb0-11ef-b302-0242ac1b0002',
            '86a0eb5b-9cb0-11ef-b302-0242ac1b0002',
            '86b61024-9cb0-11ef-b302-0242ac1b0002',
            '86cb3e44-9cb0-11ef-b302-0242ac1b0002',
            'a50685a5-9cd3-11ef-b302-0242ac1b0002',
            'a0fdc2de-ac8c-11ef-b302-0242ac1b0002',
            'a13cc546-ac8c-11ef-b302-0242ac1b0002'
          ]

          if (
            _cmdyList.some((item) =>
              _cannotUseLogisticsList.includes(item.CmdyId)
            )
          ) {
            toast.warning('包含不能超商取貨的商品', {
              position: POSITION.TOP_CENTER
            })
            store.setShowLoading(false)
          } else {
            const data = {
              Data: _shoppingCartDetailInfo
            }
            request
              .post(
                window._env_.VUE_APP_STOREBOOK_SERVICE_API +
                  requestUrl.saveTempShoppingCart,
                data
              )
              .then((res) => {
                const resData = res.data.Data
                tempShoppingCartId.value = resData.TempShoppingCartId
                window.location.href =
                  window._env_.VUE_APP_STOREBOOK_SERVICE_API +
                  '/ecpay/redirect-to-logistics-selection?Token=' +
                  getToken() +
                  '&TempShoppingCartId=' +
                  tempShoppingCartId.value +
                  '&Amount=' +
                  _shoppingCartDetailInfo.Payment.TotalAmount +
                  '&ReceiverName=' +
                  _shoppingCartDetailInfo.Receiver.ReceiverName +
                  '&ReceiverMobile=' +
                  _shoppingCartDetailInfo.Receiver.ReceiverMobile
              })
              .catch((error) => {
                store.setShowLoading(false)
                toast.warning(error.data.Message, {
                  position: POSITION.TOP_CENTER
                })
                console.error(error)
              })
          }
        }
      }
    }
    const goCouponSelectModal = () => {
      showSelectCouponModal.value = true
    }
    const goStep = (selectStep: any) => {
      const appHTMLBodyElement = document.getElementById(
        'app'
      ) as HTMLBodyElement
      appHTMLBodyElement.scrollTo(0, 0)

      if (selectStep === 2) {
        store.setShowLoading(true)
        selectCouponList.value = []
        calcShoppingCart()
          .then(() => {
            store.setShowLoading(false)
            step.value = selectStep
          })
          .catch((error) => {
            console.error(error)
            alert(error.data.Message)
            store.setShowLoading(false)
          })
      } else {
        step.value = selectStep
      }
      console.log(cmdyListFromCalc.value)
    }
    const goProductDetail = (_cmdy: any) => {
      if (
        _cmdy.FrontendParameter === undefined ||
        _cmdy.FrontendParameter === ''
      ) {
        const _appHTMLBodyElement = document.getElementById(
          'app'
        ) as HTMLBodyElement
        _appHTMLBodyElement.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
        if (_cmdy.CmdyId === undefined || _cmdy.CmdyId === '') {
          router.push({
            name: 'productDetail',
            query: {
              cmdyId: _cmdy.RefIdSet.CmdyId
            }
          })
        } else {
          router.push({
            name: 'productDetail',
            query: {
              cmdyId: _cmdy.CmdyId
            }
          })
        }
      } else {
        window.location.href = unescapeHTML(_cmdy.FrontendParameter)
      }
    }
    const goIpInspiration = () => {
      router.push({
        name: 'ipinspiration'
      })
    }
    const setCity = () => {
      const selectCity = find(cartStore.state.deliveryShopList, {
        RegionName: selectDeliveryCity.value
      }) as DeliveryShop
      console.log('selectCity ', selectCity)
      if (selectCity) {
        selectDeliveryShop.value = ''
        cartStore.setDeliveryShopFilterByRegionList(selectCity.ShopList)
      }
    }

    const setDelivery = () => {
      const _shoppingCartDetailInfo = cloneDeep(
        cartStore.state.shoppingCartDetailInfo
      )

      // const selectShop = find(cartStore.state.deliveryShopFilterByRegionList, {
      //   ShopCode: selectDeliveryShop.value
      // })

      // console.log(selectShop)

      _shoppingCartDetailInfo.Delivery = {
        DeliveryDestCode:
          cartStore.state.shoppingCartDetailInfo.Delivery.ShopCode,
        DeliveryDestName:
          cartStore.state.shoppingCartDetailInfo.Delivery.ShopName
      }

      cartStore.setShoppingCartDetailInfo(_shoppingCartDetailInfo)
    }
    onBeforeUnmount(() => {
      document.body.removeAttribute('style')
    })
    const step2Back = () => {
      if (step.value === 2) {
        step.value = 1
        router.push({ name: 'shoppingCart', query: {} })
        router.go(1)
      }
    }
    const getTempShoppingCart = () => {
      store.setShowLoading(true)
      const data = {
        Data: {
          TempShoppingCartId: tempShoppingCartId.value
        }
      }
      request
        .post(
          window._env_.VUE_APP_STOREBOOK_SERVICE_API +
            requestUrl.getTempShoppingCart,
          data
        )
        .then((res) => {
          const resData = res.data.Data
          calcShoppingCartObject.value = resData
          allItemSelect(true)
          orderShoppingCartCmdyListRef.value =
            orderShoppingCartCmdyListRef.value.map(
              (item: OrderShoppingCartCmdy) => {
                item.Checked = false
                resData.CmdyList.forEach((cmdyItem: OrderShoppingCartCmdy) => {
                  if (
                    item.CmdyId === cmdyItem.CmdyId &&
                    item.PackageCmdyList[0]?.CmdyId ===
                      cmdyItem.PackageCmdyList[0]?.CmdyId
                  ) {
                    const _addOnCmdyList = cmdyItem.AddOnCmdyList.filter(
                      (_item: any) =>
                        item.AddOnCmdyList.some(
                          (include: any) => include.CmdyId === _item.CmdyId
                        )
                    )
                    if (
                      JSON.stringify(_addOnCmdyList) ===
                      JSON.stringify(cmdyItem.AddOnCmdyList)
                    ) {
                      item.Checked = true
                    }
                  }
                })
                return item
              }
            )
          console.log(orderShoppingCartCmdyListRef.value)
          console.log(getSelectedItem.value)
          console.log(resData.CmdyList)

          buyerName.value = resData.Buyer.BuyerName
          buyerMobile.value = resData.Buyer.BuyerMobile
          buyerEmail.value = resData.Buyer.BuyerEmail
          buyerCity.value = resData.Buyer.BuyerCity
          buyerDist.value = resData.Buyer.BuyerDist
          buyerAddr.value = resData.Buyer.BuyerAddr

          selectDeliveryType.value = resData.Delivery.DeliveryType
          selectDeliverySubType.value = resData.Delivery.DeliverySubType
          selectDeliverySubTypeDesc.value = resData.Delivery.DeliverySubTypeDesc

          const _shoppingCartDetailInfo = cloneDeep(
            cartStore.state.shoppingCartDetailInfo
          )
          _shoppingCartDetailInfo.Delivery = resData.Delivery
          _shoppingCartDetailInfo.Receiver = {
            ReceiverName: resData.Receiver.ReceiverName,
            ReceiverTel: resData.Receiver.ReceiverTel,
            ReceiverMobile: resData.Receiver.ReceiverMobile,
            ReceiverEmail: resData.Receiver.ReceiverEmail
          }
          cartStore.setShoppingCartDetailInfo(_shoppingCartDetailInfo)

          cartStore.state.paymentTypeList.forEach((item: any) => {
            if (item.Code === resData.Payment.PaymentType) {
              selectPaymentType.value = item
            }
          })

          selectInvoiceType.value = resData.Invoice.InvoiceType
          invoiceCarrier.value = resData.Invoice.InvoiceCarrier
          invoiceBizNo.value = resData.Invoice.InvoiceBizNo
          invoiceEmail.value = resData.Invoice.InvoiceEmail
          // selectCouponList.value.forEach((item, index) => {
          //   if (item.PromotionCouponId === cmdyCoupon.PromotionCouponId) {
          //     item.IsUsing === true
          //   }
          // })
          selectedAvailableAddOnCmdyList.value = map(
            resData.AddOnCmdyList,
            (item: AddOnCmdy) => {
              item.Checked = true
              return item
            }
          )
          store.setShowLoading(true)
          selectCouponList.value = []
          calcShoppingCart()
            .then(() => {
              selectedAvailableAddOnCmdyList.value = map(
                addOnCmdyListFromCalc.value,
                (item: AddOnCmdy) => {
                  item.Checked = true
                  return item
                }
              )
              resData.PromotionList.forEach((promotionItem: any) => {
                console.log(calcShoppingCartObject.value.CmdyCouponList)
                map(
                  calcShoppingCartObject.value.CmdyCouponList,
                  (item: any) => {
                    if (
                      item.PromotionCouponId === promotionItem.PromotionCouponId
                    ) {
                      item.IsUsing = true
                    }
                  }
                )
              })
              selectCouponList.value = filter(
                calcShoppingCartObject.value.CmdyCouponList,
                (item: CmdyCoupon) => {
                  return item.IsUsing === true
                }
              )
              calcShoppingCart()
              store.setShowLoading(false)
            })
            .catch(() => {
              store.setShowLoading(false)
            })

          // orderShoppingCartCmdyListRef.value
          goStep(2)
          store.setShowLoading(false)
        })
        .catch((error) => {
          store.setShowLoading(false)
          console.error(error)
        })
    }
    const addAddOn = (cmdy: any) => {
      let _isChosen = false
      selectedAvailableAddOnCmdyList.value.forEach((item) => {
        if (
          item.CmdyId === cmdy.CmdyId &&
          item.PromotionActivityId === cmdy.PromotionActivityId
        ) {
          _isChosen = true
        }
      })
      if (!_isChosen) {
        if (cmdy.Checked === undefined && cmdy.Selected === undefined) {
          cmdy.Checked = true
          cmdy.Selected = true
          if (cmdy.CmdyQty === 0) {
            cmdy.CmdyQty = 1
          }
        }
        selectedAvailableAddOnCmdyList.value.push(cmdy)
      }
      toast.success('已加入購物車', {
        position: POSITION.TOP_CENTER
      })
      // const _orderShoppingCartCmdyList = map(
      //   availableAddOnCmdyListFromCalc.value,
      //   (item: any) => {
      //     if (cmdy.CmdyId === item.CmdyId)
      //     if (
      //       cmdy.CmdyId === item.CmdyId &&
      //       item.Checked === undefined &&
      //       item.Selected === undefined
      //     ) {
      //       item.Checked = true
      //       item.Selected = true
      //       if (item.CmdyQty === 0) {
      //         item.CmdyQty = 1
      //       }
      //     }
      //     return item
      //   }
      // )
      // availableAddOnCmdyListFromCalc.value = _orderShoppingCartCmdyList
      store.setShowLoading(true)
      calcShoppingCart()
        .then(() => {
          store.setShowLoading(false)
        })
        .catch(() => {
          store.setShowLoading(false)
        })
    }

    const getListAmount = (cmdyList: any[]) => {
      let _amount = 0
      cmdyList?.forEach((item) => {
        _amount += item.CmdyQty * item.UnitFeeReal
      })
      return _amount
    }

    watch(
      () => getCheckedAvailableAddOnCmdyList.value,
      (newVal, oldVal) => {
        if (newVal.length !== oldVal.length && newVal.length !== 0) {
          store.setShowLoading(true)
          calcShoppingCart()
            .then(() => {
              store.setShowLoading(false)
            })
            .catch(() => {
              store.setShowLoading(false)
            })
        }
      }
    )

    // watch(
    //   () => route.query.Status,
    //   (newVal) => {
    //     const _temp = route.query.TempShoppingCartId as string
    //     if (newVal === 'success' && _temp !== '') {
    //       // goStep(2)
    //       getTempShoppingCart()
    //     }
    //   }
    // )

    onMounted(() => {
      window.addEventListener('popstate', step2Back)
      store.setShowLoading(true)
      if (store.state.userInfo.InvoiceCarrier !== null && store.state.userInfo.InvoiceCarrier !== '') {
        invoiceCarrier.value = store.state.userInfo.InvoiceCarrier
      }
      const _temp = route.query.TempShoppingCartId as string
      if (_temp !== undefined) {
        step.value = 2
      }
      cartStore
        .fetchShoppingCartList()
        .then((res) => {
          orderShoppingCartCmdyListRef.value = cloneDeep(
            res.orderShoppingCartCmdyList
          )
          if (
            route.query.Status !== '' &&
            _temp !== '' &&
            route.query.Status !== undefined &&
            _temp !== undefined
          ) {
            getTempShoppingCart()
          }
          if (
            route.query.Status === 'failure' &&
            route.query.Message !== undefined
          ) {
            const errorMessage = route.query.Message as string
            toast.warning(errorMessage, {
              position: POSITION.TOP_CENTER
            })
          }
          store.setShowLoading(true)
          calcShoppingCart()
            .then(() => {
              store.setShowLoading(false)
            })
            .catch(() => {
              store.setShowLoading(false)
            })
          store.setShowLoading(false)
          if (orderShoppingCartCmdyListRef.value.length === 0) {
            toast.warning('購物車內目前沒有商品', {
              position: POSITION.TOP_CENTER
            })
            router.push({
              name: 'home'
            })
          }
        })
        .catch(() => {
          store.setShowLoading(false)
          toast.warning('購物車取得失敗，已幫你轉導首頁', {
            position: POSITION.TOP_CENTER
          })
          router.push({
            name: 'home'
          })
        })
      productStore.fetchRecommended('', '', 'CmdyAlsoLike').then((res) => {
        recommendedList.value = res.recommendedList
      })
    })
    onUnmounted(() => {
      window.removeEventListener('popstate', step2Back)
    })
    return {
      showSelectCouponModal,
      toast,
      router,
      confirmModal,
      isAllowReturn,
      isAllowReceive,
      isAllowInfoCorrect,
      isRecordBarcode,
      isSameMemberEmail,
      isSameMemberBarcode,
      step,
      remark,
      recommendedList,
      invoiceEmail,
      invoiceBizNo,
      invoiceCarrierType,
      invoiceCarrier,
      showDeliveryListModal,
      buyerAddr,
      buyerZip,
      buyerCity,
      buyerDist,
      buyerEmail,
      buyerMobile,
      buyerName,
      store,
      cartStore,
      productStore,
      orderStore,
      getSelectedItem,
      getAllSelectValue,
      getSelectPrice,
      selectPaymentType,
      selectInvoiceType,
      orderShoppingCartCmdyListRef,
      freightAmt,
      selectDeliveryType,
      selectDeliveryShop,
      selectDeliverySubType,
      selectDeliverySubTypeDesc,
      selectDeliveryCity,
      setCity,
      SuccessOrderItem,
      sendOrder,
      cmdyListFromCalc,
      availableAddOnCmdyListFromCalc,
      selectedAvailableAddOnCmdyList,
      getCheckedAvailableAddOnCmdyList,
      addOnCmdyListFromCalc,
      giveawayCmdyListFromCalc,
      calcShoppingCartObject,
      couponIsUpdate,
      selectCouponList,
      setDelivery,
      parseDeliveryTypeCode,
      parseInvoiceTypeCode,
      allItemSelect,
      subtractClick,
      addClick,
      itemUpdate,
      itemDelete,
      order,
      addToCollect,
      getFreight,
      goDeliveryListModal,
      goStep,
      goCouponSelectModal,
      continueEdit,
      goProductDetail,
      unescapeHTML,
      goIpInspiration,
      cancelCoupon,
      getListAmount,
      addAddOn,
      addOnItemUpdate,
      setSelectAddOnCmdy,
      showAddOnModal,
      selectedType,
      selectAddOnCmdy
    }
  }
})
